.cookies-eu-banner {
  width: 100%;
  color: #fff;
  position: relative;
  top: 0;
  background-color: #000;
  z-index: 9999999999;
  left: 0;
  right: 0;
  margin-top: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transition: top 0.4s ease;
}

.cookies-eu-banner.loaded {
  display: inline !important;
}

.cookies-eu-banner .cookietext {
  display: block;
  position: relative;
  text-align: center;
  padding: 6px;
  font-size: 16px;
}

.cookies-eu-banner .cookietext a {
  color: #fff;
  text-decoration: underline;
  font-size: 16px;
}

.cookies-eu-banner .cookietext a:hover {
  opacity: 0.8;
}

@media (max-width: 991px) {
  .cookies-eu-banner {
    position: fixed;
    bottom: 0;
    top: auto;
  }
}

@media (max-width: 768px) {
  .cookies-eu-banner .cookietext {
    font-size: 16px;
    width: 85%;
  }
}

.cookies-eu-banner .cookietext, .cookies-eu-banner .cookielink {
  box-sizing: border-box;
}

.cookies-eu-banner .closebutton {
  position: absolute;
  top: 50%;
  right: 16px;
  text-indent: -9999px;
  bottom: 0;
  width: 32px;
  height: 25px;
  opacity: .6;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  transform: translateY(-50%);
}

.cookies-eu-banner .closebutton:hover {
  opacity: 1;
}

.cookies-eu-banner .closebutton:before,
.cookies-eu-banner .closebutton:after {
  position: absolute;
  content: ' ';
  height: 25px;
  width: 2px;
  background-color: #fff;
  left: 20px;
  top: 0px;
}

.cookies-eu-banner .closebutton:before {
  transform: rotate(45deg);
}

.cookies-eu-banner .closebutton:after {
  transform: rotate(-45deg);
}

.header--scrolled .cookies-eu-banner {
  top: 36px;
}
